import gql from "graphql-tag.macro";

export const m_gauge_line_create = gql`
  mutation gauge_line_create ($input: GaugeLineCreateInput!) {
    gauge_line_create (input: $input) {
      id
    }
  }
`;

export const m_gauge_line_delete = gql`
  mutation gauge_line_delete ($id: ID!) {
    gauge_line_delete (id: $id) {
      id
    }
  }
`;

export const m_gauge_line_update = gql`
  mutation gauge_line_update ($id: ID!, $input: GaugeLineUpdateInput!) {
    gauge_line_update (id: $id, input: $input) {
      id
    }
  }
`;

export const q_gauge_line_update = gql`
  query gauge_line_update ($id: ID!) {
    gauge_line (id: $id) {
      line
      id_invoice
      end_customer_po
      article_number
      price
      model_code
      customer_code
      label_notes
      notes
      id_customer_label
      id_customer_documents
      connection_adapter
      process_connection
      max_pressure
      max_temperature
      max_accuracy
      is_upside_down
      require_calibration_certificate
      require_test_certificate
      mounting
      bracket
      dial {
        customer_logo
        case_diameter
        case_material
        window_material
        filling_material
        has_twisted_pointer
        has_follower_pointer
        has_flange
        id_dial_template
      }
      switches {
        id
        type
        has_relay
        has_din_plug
        setpoint
        is_ascending
        range_to
        id_switch
        id_unit
      }
    }
  }
`;

export const q_form_gauge_line = gql`
  query gauge_line_form {
    gauge_serieses (limit: 10000) {
      id
      name
    }

    customers (limit: 10000) {
      id
      name
    }

    dial_templates (limit: 10000) {
      id
      notes
    }

    gauge_line_mounting_options
    gauge_line_bracket_options

    unit_measures (limit: 10000) {
      id
      name
      notes
    }
  }
`;

export const q_detail_gauge_line = gql`
  query gauge_line ($id: ID!) {
    gauge_line (id: $id) {
      id
      sales_order {
        id
        currency {
          id
          symbol
        }
      }
      line
      invoice {
        id
        status
      }
      model_code
      customer_code
      end_customer_po
      price
      quantity
      amount
      notes
      customer_documents {
        name
      }
      customer_label {
        name
      }
      connection_adapter
      process_connection
      max_pressure
      max_temperature
      max_accuracy
      mounting
      article_number
      bracket
      dial {
        id_dial_template
        dial_template {
          notes
        }
      }
      switches {
        setpoint
        range_to
        unit {
          id
          name
        }
        is_ascending
      }
      scales {
        id
        scale_number
        is_ascending
        multiplier
        unit {
          id
          name
        }
        colorbands {
          from
          to
          color
          hidden
        }
        deadband_from
        deadband_to
        label_text
        scale {
          range_from
          range_to
          major_markings
          minor_markings
          text_markings
          callout_markings
        }
      }
      gauges {
        id
        line_item
        tag_number
        assembled_at
        angle_checked_at
        dial_calibrations {
          calibrated_at
        }
        dial_crosschecks {
          checked_at
        }
        switch_crosschecks {
          checked_at
        }
        finished_at
        checked_at
        packed_at
      }
    }
  }
`;

// GAUGE QUERIES
export const m_gauge_create = gql`
mutation gauge_line_gauge_create ($input: GaugeLineGaugeCreateInput!) {
  gauge_line_gauge_create (input: $input) {
    id
  },
}`;

export const m_gauge_delete = gql`
mutation gauge_line_gauge_delete ($id: ID!) {
  gauge_line_gauge_delete (id: $id) {
    id
  }
}`;

export const m_gauge_update = gql`
mutation gauge_line_gauge_update ($id: ID!, $input: GaugeLineGaugeUpdateInput!) {
  gauge_line_gauge_update (id: $id, input: $input) {
    id
  },
}`;

export const q_gauge_update = gql`
query gauge_line_gauge_update ($id: ID!) {
  gauge_line_gauge (ids: [$id]) {
    tag_number
  },
}`;

// GAUGE SCALE QUERIES
export const m_gauge_line_scale_delete = gql`
mutation gauge_line_scale_delete ($id: ID!) {
  gauge_line_scale_delete (id: $id) { 
    id
  }
}`;

export const m_gauge_line_scale_create = gql`
mutation gauge_line_scale_create ($id_gauge_line: ID!, $input: GaugeLineScaleInput!){
  gauge_line_scale_create(id_gauge_line: $id_gauge_line, input: $input) {
    id
  },
}`;

export const m_gauge_line_scale_update = gql`
mutation gauge_line_scale_update ($id: ID!, $input: GaugeLineScaleInput!){
  gauge_line_scale_update (id: $id, input: $input) {
    id
  },
}`;

export const q_update_gauge_line_scale = gql`
query update_gauge_line_scale ($id: ID!) {
  gauge_line_scale (id: $id) {
    scale_number
    id_scale
    multiplier
    id_unit
    is_ascending
    dial_multiplier
    label_text
    colorbands {
      from
      to
      color
      hidden
    }
    deadband_from
    deadband_to
  },
}`;

export const q_form_gauge_line_scale = gql`
query gauge_line_scale_form {
  gauge_scales (limit: 10000, sort: { range_from: ASC, range_to: ASC }) {
    id
    notes
    major_markings
    minor_markings
    range_from
    range_to
  }

  unit_measures (limit: 10000, sort: { name: ASC }) {
    id
    name
    notes
  }
}`;