import { FastField, Field, useFormikContext } from "formik";
import React from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { Form } from 'semantic-ui-react';

const defaultFunctions = {
  formatOptionLabel: ({ label, subLabel }) => (
    <div>
      <div>{label}</div>
      {subLabel && <div style={{ fontSize: '0.9em', color: 'hsl(0,0%,40%)' }}>{subLabel}</div>}
    </div>
  ),
  filterOption: (candidate, input) => candidate.label?.toLowerCase().includes(input.toLowerCase()) 
    || candidate.data?.subLabel?.toLowerCase().includes(input.toLowerCase()),
  getOptionValue: (opt) => opt?.value,
}

const SelectField = ({ label, styles, width, fast=false, isCreatable=false, ...props }) => {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const SelectComponent = isCreatable ? CreatableSelect : Select;
  const FieldComponent = fast ? FastField : Field;
  const getOptionValue = props.getOptionValue ?? defaultFunctions.getOptionValue;

  return (
    <FieldComponent name={props.name}>
      {({ field, meta }) => (
        <Form.Field width={width} error={meta.touched && (meta.error != null)} >
          <label>{label}</label>
          <SelectComponent
            styles={{
              ...styles,
              control: provided => ({ ...provided, borderColor: meta.touched && meta.error ? '#9f3a38' : provided.borderColor }),
            }}
            {...defaultFunctions}
            onChange={opt => setFieldValue(props.name, getOptionValue(opt))}
            onBlur={() => setFieldTouched(props.name)}
            // eslint-disable-next-line eqeqeq
            value={props.options.find(opt => getOptionValue(opt) == field.value) 
              ?? (field.value == null ? null : { id: -1, value: field.value, label: field.value })
              ?? []
            }
            {...props}
          />
        </Form.Field>
      )}
    </FieldComponent>
  );
}

export default SelectField;