import { useMutation, useQuery } from '@apollo/client';
import { Formik } from 'formik';
import { Button, Container, Form, Header, Icon } from 'semantic-ui-react';
import { FONT_OPTIONS } from 'src/misc/dropdownOptions.js';
import { stripTypename } from 'src/misc/helpers';
import PageHeader from 'src/misc/PageHeader';
import SemanticField from 'src/misc/SemanticField';
import { isEmptyString } from 'src/misc/validators';
import * as yup from 'yup';
import * as QUERIES from './queries';

const validationSchema = yup.object().shape({
  d: yup.number().required(),
  pinion_y_offset: yup.number().required(),
  fg_color: yup.string().required().default('#000000'),
  bg_color: yup.string().nullable(),
  has_square_markings: yup.bool().default(false),
  has_decimal_commas: yup.bool().default(false),
  notes: yup.string().nullable(),
  porting_x: yup.number().required(),
  porting_y: yup.number().required(),
  porting_d: yup.number().nullable(),
  porting_arrow_w: yup.number().nullable(),
  porting_arrow_h: yup.number().nullable(),
  porting_arrow_y: yup.number().nullable(),
  unit_text_x: yup.number().required(),
  unit_text_y: yup.number().required(),
  unit_text_angle: yup.number().required(),
  unit_text_fontsize: yup.number().required(),
  unit_text_fontname: yup.string().required(),
  unit_text_draw: yup.bool().default(true),
});

const DialTemplateForm = ({ history, match }) => {
  const isUpdateForm = match.params.id != null;
  const [updateMutation] = useMutation(QUERIES.m_update);
  const [createMutation] = useMutation(QUERIES.m_create);
  const updateQuery = useQuery(QUERIES.q_update, { variables: { id: match.params.id }, skip: !isUpdateForm });
  
  if (updateQuery?.loading) return null;
  if (updateQuery.error)  return `Error!: ${JSON.stringify(updateQuery.error, null, 2)}`;

  const handleSubmit = async (rawFields) => {
    const fields = validationSchema.cast(rawFields);
    delete fields.id;
    if(isEmptyString(fields.bg_color)) fields.bg_color = null;
    if(isEmptyString(fields.notes)) fields.notes = null;
    if(isEmptyString(fields.porting_d)) fields.porting_d = null;
    if(isEmptyString(fields.porting_arrow_w)) fields.porting_arrow_w = null;
    if(isEmptyString(fields.porting_arrow_h)) fields.porting_arrow_h = null;
    if(isEmptyString(fields.porting_arrow_y)) fields.porting_arrow_y = null;
    fields.fg_color = fields.fg_color?.trim();
    fields.bg_color = fields.bg_color?.trim();
    if (isUpdateForm) {
      await updateMutation({ variables: { id: match.params.id, input: fields }});
      history.go(-1);
    } else {
      const { data } = await createMutation({ variables: { input: fields } });
      history.push(`./${data.dial_template_create.id}`);
    }
  }

  const handleReset = () => {
    history.go(-1);
  }
  const initialValues = stripTypename(updateQuery?.data?.dial_template ?? validationSchema.default());

  return (
    <>
      <PageHeader title={`${isUpdateForm ? 'Update' : 'Create'} Dial Template`} />
      <Container style={{ paddingTop: '48px' }}>
        <Formik 
          initialValues={initialValues} enableReinitialize 
          onSubmit={handleSubmit} onReset={handleReset}
          validationSchema={validationSchema}
        >
          {formikProps => (
            <Form onSubmit={formikProps.handleSubmit} onReset={formikProps.handleReset}>
              <Form.Group widths="equal">
                <SemanticField fast name="d" component={Form.Input} label="Dial diameter (mm)" type="text" />
                <SemanticField fast name="pinion_y_offset" component={Form.Input} label="Pinion hole Y offset from dial center (mm)" type="text" />
              </Form.Group>
              <Form.Group widths="equal">
                <SemanticField fast name="fg_color" component={Form.Input} label="Foreground color" />
                <SemanticField fast name="bg_color" component={Form.Input} label="Background color" />
              </Form.Group>
              <Form.Group widths="equal">
                <SemanticField fast name="has_square_markings" component={Form.Checkbox} label="Square scale markings" />
                <SemanticField fast name="has_decimal_commas" component={Form.Checkbox} label="Commas for decimal point" />
              </Form.Group>
              <SemanticField fast name="notes" component={Form.TextArea} label="Notes" />
              <Header as="h2">Porting</Header>
              <Form.Group widths="equal">
                <SemanticField fast name="porting_x" component={Form.Input} label="Porting symbol X (mm)" type="text" />
                <SemanticField fast name="porting_arrow_w" component={Form.Input} label="Porting arrow max width (mm)" type="text" />
              </Form.Group>
              <Form.Group widths="equal">
                <SemanticField fast name="porting_y" component={Form.Input} label="Porting symbol Y (mm)" type="text" />
                <SemanticField fast name="porting_arrow_h" component={Form.Input} label="Porting arrow max height (mm)" type="text" />
              </Form.Group>
              <Form.Group widths="equal">
                <SemanticField fast name="porting_d" component={Form.Input} label="Porting symbol diameter (mm)" type="text" />
                <SemanticField fast name="porting_arrow_y" component={Form.Input} label="Porting arrow center Y (mm)" type="text" />
              </Form.Group>
              <Header as="h2">Unit text</Header>
              <Form.Group widths="equal">
                <SemanticField fast name="unit_text_x" component={Form.Input} label="Unit text X (mm)" type="text" />
                <SemanticField fast name="unit_text_fontname" selection component={Form.Dropdown} label="Unit text font name" options={FONT_OPTIONS} />
              </Form.Group>
              <Form.Group widths="equal">
                <SemanticField fast name="unit_text_y" component={Form.Input} label="Unit text Y (mm)" type="text" />
                <SemanticField fast name="unit_text_fontsize" component={Form.Input} label="Unit text font size (mm)" type="text" />
              </Form.Group>
                <SemanticField fast name="unit_text_angle" component={Form.Input} label="Text angle (deg)" type="text" />
                <SemanticField fast name="unit_text_draw" component={Form.Checkbox} label="Leave unchecked when individual scales unit texts required" />

              <Button color="red" icon labelPosition="left" type="reset">
                <Icon name="cancel" />Discard
              </Button>
              <Button icon labelPosition="left" type="submit" disabled={formikProps.isSubmitting || !formikProps.dirty}>
                <Icon name="save" />Save
              </Button>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  )
}

export default DialTemplateForm;