import { useEffect } from "react";

const RemoveTrailingSlash = ({ history, location }) => {
  useEffect(() => {
    // If the last character of the url is '/'
    if (location.pathname.match('/.*/$')) {
      history.replace({
        pathname: location.pathname.replace(/\/+$/, ""),
        search: location.search
      });
    }
  }, [location, history]);
  return null;
}

export default RemoveTrailingSlash;